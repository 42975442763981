<template>
  <div class="goods">
    <!-- <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="thumb in goods.thumb" :key="thumb">
        <img  @click="handleClick" :src="thumb" >
      </van-swipe-item>
    </van-swipe> -->

    <div class="image-container">
        <img  style="width: -webkit-fill-available;" :src="thumb1" >
        <p class="p1">白条</p>
        <p class="p2">{{ supplier }}</p>
        <p class="p3">{{ zdttuzaiStr }}</p>
    </div>
  </div>
</template>

<script>

import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from 'vant';

export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton
  },

  data() {
    return {
      goods: {
        title: '牛肉',
        price: 2680,
        express: '免运费',

        remain: 19,
        thumb: [
          'https://cloud.bbrightway.com.cn/miniodata/20231128/562923d336fc4828a16483d2a62753b1.png',
          'https://cloud.bbrightway.com.cn/miniodata/20231128/562923d336fc4828a16483d2a62753b1.png'
        ],
      },        // 编号
         zsn: '',
         // 送宰时间
         zdttuzaiStr: '',
         zdttuzaiendStr: '',
         zdtchuchangStr: '',
         supplier: '',
         zsfzbh: '',
         thumb1:require('../../assets/imgs/zhuti2.png')
    };
  },
  created() {
    
    this.findList();
  },
  methods: {
    findList() {
        window.console.log(this.$route.query.data.substring(this.$route.query.data.lastIndexOf(":") +1).replace('/>', ''))
        let params = {
            scanCode: this.$route.query.data.substring(this.$route.query.data.lastIndexOf(":") +1).replace('/>', '')
        }
        this.$axios.get('/zhu/hphnCmTuzai/queryHphnCmTuzailById', {params}).then( res => {
            window.console.log(res)
            if(res.data.code === 0) {
            //
            this.zsn = res.data.data.zsn
            this.zdttuzaiStr = res.data.data.zdttuzaiStr
            this.zdttuzaiendStr = res.data.data.zdttuzaiendStr
            this.zdtchuchangStr = res.data.data.zdtchuchangStr
            this.supplier = res.data.data.supplier
            this.zsfzbh = res.data.data.zsfzbh
            
            }
        });
    },
    handleClick() {
        window.location.href = 'https://baidu.com';
    },
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },

    onClickCart() {
      this.$router.push('cart');
    },

    sorry() {
      Toast('暂无后续逻辑~');
    }
  }
};
</script>
<style scoped>
.goods {
    /* background-color: red; */
    /* height:190rem;
    background-repeat: no-repeat;
    background-image: url('https://cloud.bbrightway.com.cn/miniodata/20231212/0785a89aebde4cdc9f47f14f742d6f39.jpg');
    background-size:contain */
}
.image-container {
  position: relative;
  text-align:right;
}
.image-container img {
  display: block;
}
.image-container .p1 {
    position: absolute;
    top: 30.1%;
    color: whitesmoke;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-27%, -50%);
}
.image-container .p2 {
    position: absolute;
    top: 31.5%;
    color: whitesmoke;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-27%, -50%);
}
.image-container .p3 {
    position: absolute;
    top: 32.9%;
    color: whitesmoke;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-27%, -50%);
}
</style>
<!-- <style lang="less">
.goods {
//   padding-bottom: 50px;
  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
} -->
<!-- </style> -->
